import { MyButton } from "./components/Mybutton";
import { Counter } from "./components/Counter";
import "./style.css";
import { Movies } from "./components/Movies";
import { InputItem } from "./components/InputItem";
import { useState } from "react";

const buttonLabels = ["hey", "hoy", "Hallo", "Dag"];

const App = () => {
  const [text, setText] = useState();

  return (
    <div>
      <h1 className="title" style={{ fontSize: 100, color: "black" }}>
        Hello
      </h1>
      <InputItem setText={setText} text={text} />
      <br />
      {buttonLabels.map((label) => (
        <MyButton label={label} heyLabel={text} setText={setText} />
      ))}
      <Counter />
      <Movies />
    </div>
  );
};

export default App;
