import { useState } from "react";
import { Box, Button } from "@mui/material";

export const Counter = () => {
  const [count, setCount] = useState(0);

  const onDown = () => {
    setCount((prev) => {
      return prev - 1;
    });
  };
  const onUp = () => {
    setCount(count + 1);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <h1>{count}</h1>
      <Box display="flex" gap={2}>
        <Button variant="contained" onClick={onDown}>
          Down
        </Button>
        <Button variant="contained" onClick={onUp}>
          Up
        </Button>
      </Box>
    </Box>
  );
};
